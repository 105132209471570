import { render, staticRenderFns } from "./CarrShipFromAddrUpdate.vue?vue&type=template&id=63d6f488&scoped=true&"
import script from "./CarrShipFromAddrUpdate.vue?vue&type=script&lang=js&"
export * from "./CarrShipFromAddrUpdate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63d6f488",
  null
  
)

export default component.exports