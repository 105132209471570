<template>
	<div class="mainTem">

		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<span class="tct_tit">渠道发货地址配置</span>
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<el-button type="success" @click="openDioalog()" size="small"
						icon="el-icon-plus">{{ $t('c0246c55b9cac963') }}</el-button>
						
					<el-button @click="delAction($event, null)" type="danger" size="small"
						icon="el-icon-minus">{{ $t('FormMsg.Delete') }}</el-button>
					
					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div>

		<div class="filterCon" style="">
			<ul class="filterConList">
				<!-- <li>
					<span>{{ $t('hytxs0000060') }}</span>
					<cusSelFuzzy ref="cusSelFuzzy" :size="'small'" @changeData="changCus"></cusSelFuzzy>
				</li>
				<li>
					<span>{{ $t('c944a6686d996ab3') }}</span>
					<whNoSelect ref="whNoSelect" :size="'small'" @changeData="changWhNo"></whNoSelect>
				</li> -->
				<li>
					<span>lgsKeyCode</span>
					<el-select filterable clearable size="small" v-model="filterData.lgsKeyCode"
						:placeholder="$t('2ad108ab2c560530')" style="width: 200px;" @change="initData()">
						<el-option v-for="(item,index) in selectOption.biz_wh_lgskey_code" :key="index"
							:label="item.logistics+'('+item.code+')'+'('+item.codeName+')'" :value="item.code">
							<div style="display: flex; justify-content: space-between;">
								<div>{{ item.logistics }}({{ item.code }})({{ item.codeName }})</div>
								<div style="color: #8492a6; font-size: 13px">
									<span v-if="0 == item.isUse" style="color: red;">{{$t('i18nn_ab5902ee5c645e01')}}</span>
									<span v-else style="color: #66b1ff;">{{$t('i18nn_3f0ecebbd9d2f23b')}}</span>
								</div>
							</div>
						</el-option>
					</el-select>
				</li>
				
				<li>
					<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
					<el-input type="text" v-model="filterData.keyword" size="small" clearable @keyup.enter.native="initData()"
						maxlength="50" :placeholder="$t('custom_enterfuzzysearch')" style="width: 220px;" />
				</li>
				<li>
					<el-button icon="el-icon-search" size="small" type="primary" @click="initData()">
						{{ $t('1e7246dd6ccc5539') }}
					</el-button>
				</li>
			</ul>
		</div>

		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
			<el-table id="out-table2" ref="multipleTable" :data="tableData" stripe :border="true"
				:height="$store.state.tableMaxHeight2" @row-click="handleCurrentChange"
				@selection-change="handleSelectionChange" style="width: 100%" size="small">
				<el-table-column type="selection" fixed="left" align="center" width="50"
					label-class-name="nodra"></el-table-column>

				<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
					:label="$t('Storage.tableColumn.no')"></el-table-column>

				<!-- "lgsId":"","lgsCode":"","countryCode":"","addresses1":"","addresses2":"","city":"","phone":"","provinceCode":"","postalCode":"","addrName":"" -->
				<el-table-column prop="lgsId" :label="'lgsId'"></el-table-column>

				<el-table-column prop="lgsCode" :label="'lgsCode'"></el-table-column>
				
				<el-table-column prop="addrName" :label="'地址名称'"></el-table-column>
				
				<el-table-column prop="name" :label="'发件人名称'"></el-table-column>
				<el-table-column prop="phone" :label="'电话'"></el-table-column>
				
				<el-table-column prop="addresses1" :label="'地址一'"></el-table-column>
				<el-table-column prop="addresses2" :label="'地址二'"></el-table-column>
				<el-table-column prop="city" :label="'城市'"></el-table-column>
				
				
				<el-table-column prop="provinceCode" :label="'州'"></el-table-column>
				<el-table-column prop="postalCode" :label="'邮编'"></el-table-column>
				<el-table-column prop="countryCode" :label="'国家'"></el-table-column>
				
				<el-table-column prop="remark" :label="$t('15b3627faddccb1d')">
					<template slot-scope="scope">
						<el-popover placement="top" trigger="hover">
							<div class="pre-text">{{ scope.row.remark }}</div>
							<span class="over_ellipsis" slot="reference">{{scope.row.remark}}</span>
						</el-popover>
					</template>
				</el-table-column>

				<el-table-column prop="" :label="$t('i18nn_43a3586339251494')" min-width="200">
					<template slot-scope="scope">
						<div>
							<ul>
								<li>
									<span>{{$t('i18nn_e1911e9360047dcb')}}</span>:{{scope.row.updateTime}}
								</li>
								<li>
									<span>{{$t('i18nn_e8564657bbe9ca53')}}</span>:{{scope.row.createTime}}
								</li>
							</ul>
						</div>
					</template>
				</el-table-column>

				<el-table-column :label="$t('Storage.tableColumn.operation')" width="260px" fixed="right">
					<template slot-scope="scope">
						<div>
							<div style="margin-bottom: 5px;">
								<el-button @click="openEdit($event, scope.row, scope.$index)" type="warning" size="mini"
									icon="el-icon-edit">{{ $t('FormMsg.Edit') }}</el-button>
									
								<el-button @click="delAction($event, scope.row)" type="danger" size="mini"
									icon="el-icon-minus">{{ $t('FormMsg.Delete') }}</el-button>

								<!-- <el-button v-if="'10'==scope.row.status" @click="closeAction($event, scope.row)" type="warning"
									size="mini" icon="el-icon-close">{{$t('4e9fc68608c60999')}}</el-button>

								<el-button v-else @click="openAction($event, scope.row)" type="success" size="mini"
									icon="el-icon-check">{{$t('70fdf36bdc2b0f58')}}</el-button> -->
							</div>

						</div>
					</template>
				</el-table-column>
			</el-table>

			<div class="tableCon_pagination">
				<hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination>
			</div>
		</div>

		<!--编辑-->
		<CarrShipFromAddrUpdate :openTime="editOpenTime" :row="editRow" @success="editSuccessBack">
		</CarrShipFromAddrUpdate>

	</div>
</template>
<script>
	import CarrShipFromAddrUpdate from '@/components/WarehouseCenter2/thirdLogistics/CarrShipFromAddrUpdate.vue';

	// import cusSelFuzzy from '@/components/WarehouseCenter2/components/cusSelFuzzy.vue';
	// import whNoSelect from '@/components/WarehouseCenter2/components/whNoSelect.vue';

	export default {
		components: {
			// cusSelFuzzy,
			// whNoSelect,
			CarrShipFromAddrUpdate
		},
		// props: {
		// 	// mobile:"",
		// 	isSel: {
		// 		default: function() {
		// 			return false
		// 		},
		// 		type: Boolean
		// 	},
		// },
		data() {
			return {
				// whAddOpenTime: '',
				// whRow: {},
				editOpenTime:'',
				editRow:{},

				loading: false,

				multipleSelection: [],

				loading_load: false,
				tableData: [],

				//分页数据
				pagination: this.$Utils.defaultPagination(),
				selectOption: {
					biz_wh_lgskey_code: [],
				},
				//查询，排序方式
				filterData: {
					orderBy: 'sort_no', //排序字段
					sortAsc: 'N', //desc降序，asc升序
					// userName: '',
					userId: '',
					whNo: '',
					keyword: '',
					lgsKeyCode:''
				}
			};
		},
		activated() {
			this.initData();
		},
		//创建时
		created() {

		},
		//编译挂载前
		mounted() {
			// this.initData();
			this.getLGSByCodeData();
		},
		methods: {
			initData() {
				this.multipleSelection = [];

				this.pagination.current_page = 1;
				this.getPageData();
				this.$nextTick(() => {
					this.$refs.multipleTable.doLayout();
				});
			},
			

			changCus(data) {
				console.log('changCus', data);
				this.filterData.userId = data.userId;
				this.initData();
			},
			changWhNo(data) {
				console.log('changWhNo', data);
				this.filterData.whNo = data.code;
				this.initData();
			},

			//选择行
			handleCurrentChange(row, event, column) {
				// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
				console.log(val);
				this.multipleSelection = val;
			},

			//打开新增编辑，弹窗
			openDioalog() {
				// this.whRow = formParm;
				// this.whAddOpenTime = new Date().getTime();
				this.$router.push({name:"CarrShipFromAddrAdd"})
			},
			//打开编辑
			openEdit(event, row, index) {
				event.stopPropagation();
				this.editRow = row;
				this.editOpenTime = new Date().getTime();
				// this.openDioalog(row, this.$t('FormMsg.Edit'));
			},
			editSuccessBack() {
				this.initData();
			},

			//删除
			// delAction(event, row) {
			// 	this.$confirm(this.$t('FormMsg.confirm_Delete'), this.$t('tips.tipsTitle'), {
			// 			type: 'warning'
			// 		})
			// 		.then(() => {
			// 			this.postData(this.$urlConfig.WhRemoteAreaCodeConfigDel, {}, 'delete');
			// 		})
			// 		.catch(() => {});
			// },

			//删除
			delAction(event, row) {
				this.$confirm(this.$t('FormMsg.confirm_Delete'), this.$t('tips.tipsTitle'), {
						type: 'warning'
					})
					.then(() => {
						this.delDataAction(event, row);
					})
					.catch(() => {});
			},
			//删除
			delDataAction(event, row) {
				event.stopPropagation();
				console.log('delDataAction', row);
				let parm = [];

				if (!!row) {
					//单条
					parm = [row.id];
				} else {
					//多条
					let dataList = this.multipleSelection;
					if (dataList.length < 1) {
						this.$message.warning(this.$t('7b80e66b535a3732'));
						return;
					}
					let dataListParm = dataList.map(v => v.id);
					parm = dataListParm;
					// console.log('dataListParm', dataListParm);
					// let dataParm = {
					// 	ids: dataListParm
					// };
				}

				this.postData(this.$urlConfig.WhCarrierShipFromAddrDel, {ids:parm}, '', () => {
					this.$message({
						type: 'success',
						message: this.$t('tips.successfullyDelete')
					});
					this.getPageData();
				});
			},

			//开启
			// openAction(event, row) {
			// 	this.postData(this.$urlConfig.WhThirdWmsConfigOpenClose, {
			// 		id: row.id
			// 	}, '');
			// },
			// //关闭
			// closeAction(event, row) {
			// 	this.postData(this.$urlConfig.WhThirdWmsConfigOpenClose, {
			// 		id: row.id
			// 	}, '');
			// },

			//提交信息
			postData(url, formData, type, callback) {
				this.loading = true;
				this.loading_load = true;
				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('tips.submitSuccess'));
					console.log(data);

					this.loading = false;
					this.loading_load = false;
					if (200 == data.code) {
						// this.getPageData();
						// if ('delete' == type) {
						// 	this.$message({
						// 		type: 'success',
						// 		message: this.$t('tips.successfullyDelete')
						// 	});
						// } else {
						// 	this.$message.success(this.$t('tips.submitSuccess'));
						// }
						callback();
					} else {
						this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
					});
				});
			},

			//查询数据
			// serPageData() {
			// 	this.pagination.current_page = 1;
			// 	this.getPageData();
			// },

			//请求分页数据
			getPageData() {
				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhCarrierShipFromAddrList, {
						offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						limit: this.pagination.page_size, //当前页显示数目

						userId: this.filterData.userId ? this.filterData.userId : null,
						whNo: this.filterData.whNo ? this.filterData.whNo : null,
						keyword: this.filterData.keyword ? this.filterData.keyword : null,
						lgsCode: this.filterData.lgsKeyCode ? this.filterData.lgsKeyCode : null,
					})
					.then(({
						data
					}) => {
						this.loading_load = false;
						//表格显示数据
						this.tableData = data.rows;
						//当前数据总条数
						this.pagination.total = parseInt(data.total);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading_load = false;
					});
			},
			
			//lgscode
			getLGSByCodeData() {
				this.loading = true;
				this.$http
					.put(this.$urlConfig.WhPriterOrderUps2LgsList, {
						isUse: '1'
					})
					.then(({
						data
					}) => {
						console.log('查询lgs，请求成功');
						console.log(data);
						this.loading = false;
						if (200 == data.code && data.rows) {
							this.selectOption.biz_wh_lgskey_code = data.rows;
						} else {
							if (!data.msg) {
								data.msg = 'lgs' + this.$t('i18nn_323ee425eca7208c');
							}
							this.$message.warning(data.msg);
						}
					})
					.catch(error => {
						console.log(error);
						this.loading = false;
						console.log('查询数据字典接口，请求失败');
						this.$message.error('lgs' + this.$t('i18nn_323ee425eca7208c') + '！');
					});
			},

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	@import url(@/assets/css/client_module.less);
</style>