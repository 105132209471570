<template>
	<div>
		<el-drawer :wrapperClosable="false" :title="'渠道发货地址更新'" append-to-body :visible.sync="dialogFile" :direction="'rtl'"
			size="800px">
			<div style="padding: 10px;">
				<el-form ref="form" inline :rules="formRules" :model="form" label-width="150px" v-loading="loading">

					<!-- <el-form-item :label="$t('c944a6686d996ab3')" prop="">
						<whNoSelect ref="whNoSelect" :size="''" @changeData="changWhNo"></whNoSelect>
					</el-form-item>
					
					<el-form-item :label="$t('hytxs0000060')" prop="">
						<cusSelFuzzy ref="cusSelFuzzy2" :size="''" @changeData="changCus2"></cusSelFuzzy>
					</el-form-item> -->
					
					<!-- "lgsId":"",
					"lgsCode":"",
					"countryCode":"",
					"addresses1":"",
					"addresses2":"",
					"city":"",
					"phone":"",
					"provinceCode":"",
					"postalCode":"",
					"addrName":"", -->

					<!-- <el-form-item :label="'lgsId'" prop="">
						<el-input type="text" clearable v-model="form.lgsId"></el-input>
					</el-form-item> -->

					<el-form-item :label="'lgsCode'" prop="">
						<!-- <el-input v-model="form.lgsCode">
						</el-input> -->
						<el-select filterable clearable size="small" v-model="form.lgsCode"
							:placeholder="$t('2ad108ab2c560530')" style="width: 200px;" @change="lgsKeyChange()">
							<el-option v-for="(item,index) in selectOption.biz_wh_lgskey_code" :key="index"
								:label="item.logistics+'('+item.code+')'+'('+item.codeName+')'" :value="item.code">
								<div style="display: flex; justify-content: space-between;">
									<div>{{ item.logistics }}({{ item.code }})({{ item.codeName }})</div>
									<div style="color: #8492a6; font-size: 13px">
										<span v-if="0 == item.isUse" style="color: red;">{{$t('i18nn_ab5902ee5c645e01')}}</span>
										<span v-else style="color: #66b1ff;">{{$t('i18nn_3f0ecebbd9d2f23b')}}</span>
									</div>
								</div>
							</el-option>
						</el-select>
					</el-form-item>
					
					<el-form-item :label="'地址名称'" prop="">
						<el-input v-model="form.addrName">
						</el-input>
					</el-form-item>
					<el-form-item :label="'发件人名称'" prop="">
						<el-input v-model="form.name">
						</el-input>
					</el-form-item>
					
					<el-form-item :label="'电话'" prop="">
						<el-input v-model="form.phone">
						</el-input>
					</el-form-item>
					
					<el-form-item :label="'地址一'" prop="">
						<el-input v-model="form.addresses1">
						</el-input>
					</el-form-item>
					<el-form-item :label="'地址二'" prop="">
						<el-input v-model="form.addresses2">
						</el-input>
					</el-form-item>
					<el-form-item :label="'城市'" prop="">
						<el-input v-model="form.city">
						</el-input>
					</el-form-item>
					
					<el-form-item :label="'州'" prop="">
						<el-input v-model="form.provinceCode">
						</el-input>
					</el-form-item>
					<el-form-item :label="'邮编'" prop="">
						<el-input v-model="form.postalCode">
						</el-input>
					</el-form-item>
					<el-form-item :label="'国家'" prop="">
						<el-input v-model="form.countryCode">
						</el-input>
					</el-form-item>

					<el-form-item :label="'备注'" prop="">
						<el-input type="textarea" clearable v-model="form.remark"></el-input>
					</el-form-item>

				</el-form>
			</div>

			<div class="drawer-footer">
				<el-button type="primary" icon="el-icon-tickets"
					@click="submitForm('form')">{{ $t('FormMsg.Save') }}</el-button>
				<!-- <el-button type="primary" plain @click="dialogFile = false">{{$t('4e9fc68608c60999')}}</el-button> -->
			</div>
		</el-drawer>

	</div>
</template>
<script>
	import {
		deepClone,
		clearObjectVal
	} from '@/utils/utils.js';

	import {
		getDicData
	} from '@/axios/common.js';

	// import cusSelFuzzy from '@/components/WarehouseCenter2/components/cusSelFuzzy.vue';
	// import whNoSelect from '@/components/WarehouseCenter2/components/whNoSelect.vue';

	export default {

		props: {
			openTime: {
				// default: function() {
				//   return '';
				// },
				// type: String
			},
			row: {}
		},
		components: {
			// cusSelFuzzy,
			// whNoSelect
		},
		data() {
			return {
				dialogFile: false,

				loading: false,
				// form: {
				// 	"userId":"",
				// 	"thridCode":"",
				// 	"whNo":"",
				// 	"thridWhNo":"",
				// 	"apiUrl":"",
				// 	"clientKey":"",
				// 	"clientSecret":""
				// },
				form: {
					// "country":"",
					// "remoteLevel":"",
					// "zipCode":"",
					"lgsId":"",
					"lgsCode":"",
					"countryCode":"",
					"addresses1":"",
					"addresses2":"",
					"city":"",
					"phone":"",
					"provinceCode":"",
					"postalCode":"",
					"addrName":"",
					
					"id": "",
					// "feeType": "",
					// "zone2Price": "",
					// "zone3Price": "",
					// "zone4Price": "",
					// "zone5Price": "",
					// "zone6Price": "",
					// "zone7Price": "",
					// "zone8Price": "",
					// "zone9Price": "",
					// "zone10Price": "",
					// "zone11Price": "",
					// "zone12Price": "",
					// "isSupport": "", //"是否支持",
					// "isAddSf": "" //"是否加服务费",

				},

				formRules: {

				},

				selectOption: {
					biz_wh_lgskey_code:[]
				},

			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.dialogFile = true;
				this.initData();
			}
		},
		//创建时
		created() {

		},
		//编译挂载前
		mounted() {

		},
		methods: {
			initData() {
				// // 重置
				this.resetForm('form');
				this.form = Object.assign({}, this.row);
				// if (!!this.row) {
				// 	this.getDetData(this.row.id);
				// }
			},
			
			lgsKeyChange(){
				let selObj = this.selectOption.biz_wh_lgskey_code.find(item=>{
					return item.code == this.form.lgsCode;
				});
				this.form.lgsId = selObj.id;
			},

			changCus2(data) {
				console.log('changCus', data);
				this.form.userId = data.userId;
			},
			changWhNo(data) {
				console.log('changWhNo', data);
				this.form.whNo = data.code;
			},

			// getDetData(keyId) {
			// 	this.loading = true;
			// 	this.$http
			// 		.get(this.$urlConfig.WhThirdWmsConfigQuery + "/" + keyId, {})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log(data);
			// 			this.loading = false;
			// 			if (200 == data.code) {
			// 				this.form = data.data;
			// 				this.$nextTick(() => {
			// 					this.$refs.cusSelFuzzy2.init(this.form.userId);
			// 					this.$refs.whNoSelect.init(this.form.whNo);
			// 				});
			// 			} else {
			// 				this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
			// 					type: 'warning'
			// 				});
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			this.loading = false;
			// 		});
			// },

			//提交信息
			submitForm(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						let formData = Object.assign({}, this.form);
						// if(!!formData.id){
						// 	this.postData(this.$urlConfig.WhRemoteAreaCodeConfigAdd, formData, '', () => {
						// 		this.dialogFile = false;
						// 		this.$emit('success');
						// 	});
						// } else {
						this.postData(this.$urlConfig.WhCarrierShipFromAddrUpdate, formData, '', () => {
							this.dialogFile = false;
							this.$emit('success');
						});
						// }

					} else {
						console.log('error submit!!');
						this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
							type: 'warning'
						});
						return false;
					}
				});
			},
			//重置输入框
			resetForm(formName) {
				console.log(formName);
				if (this.$refs[formName]) {
					this.$refs[formName].resetFields();
					this.form = clearObjectVal(this.form);
				} else {
					console.log('this.$refs[formName]', this.$refs[formName]);
				}

				// this.$nextTick(() => {
				// 	this.$refs.cusSelFuzzy2.init('');
				// 	this.$refs.whNoSelect.init('');
				// });
			},

			//提交信息
			postData(url, formData, type, callback) {
				this.loading = true;
				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				HttpType.then(({
					data
				}) => {
					this.loading = false;
					if (200 == data.code) {
						callback();
					} else {
						this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
					});
				});
			},
			
			//lgscode
			getLGSByCodeData() {
				this.loading = true;
				this.$http
					.put(this.$urlConfig.WhPriterOrderUps2LgsList, {
						isUse: '1'
					})
					.then(({
						data
					}) => {
						console.log('查询lgs，请求成功');
						console.log(data);
						this.loading = false;
						if (200 == data.code && data.rows) {
							this.selectOption.biz_wh_lgskey_code = data.rows;
						} else {
							if (!data.msg) {
								data.msg = 'lgs' + this.$t('i18nn_323ee425eca7208c');
							}
							this.$message.warning(data.msg);
						}
					})
					.catch(error => {
						console.log(error);
						this.loading = false;
						console.log('查询数据字典接口，请求失败');
						this.$message.error('lgs' + this.$t('i18nn_323ee425eca7208c') + '！');
					});
			},

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>